<template>
  <v-card class="pa-3">
    <v-layout row>
      <v-flex md4>
        <v-btn
          block
          :color="graphType === 'CONVERSION_RATE' ? 'primary' : undefined"
          @click="setGraphType('CONVERSION_RATE')"
        >
          <v-icon small>fad fa-sack</v-icon>&nbsp; Conversion Rate
        </v-btn>
      </v-flex>
      <v-flex md4>
        <v-btn
          block
          :color="graphType === 'CONVERSIONS' ? 'primary' : undefined"
          @click.native="setGraphType('CONVERSIONS')"
        >
          <v-icon small>fad fa-file</v-icon>&nbsp; Conversions
        </v-btn>
      </v-flex>
      <v-flex md4>
        <v-btn
          block
          :color="graphType === 'VISITORS' ? 'primary' : undefined"
          @click="setGraphType('VISITORS')"
        >
          <v-icon small>fad fa-users</v-icon>&nbsp; Visitors
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout v-if="isLoading" align-center justify-center row fill-height>
      <v-progress-circular :size="50" color="primary" indeterminate />
    </v-layout>
    <div v-if="graphType === 'VISITORS'">
      <total-data-graph
        class="mb-4"
        v-if="statsLoaded"
        :total-stats="stats['visitors']['current']['daily']['total']"
        :total-last-year-stats="
          stats['visitors']['last_year']['daily']['total']
        "
        :nl-be-stats="stats['visitors']['current']['daily']['nl_be']"
        :fr-be-stats="stats['visitors']['current']['daily']['fr_be']"
        :nl-nl-stats="stats['visitors']['current']['daily']['nl_nl']"
        :fr-fr-stats="stats['visitors']['current']['daily']['fr_fr']"
        title="Daily Visistors"
      />
      <total-data-graph
        class="mb-4"
        v-if="statsLoaded"
        :total-stats="stats['visitors']['current']['monthly']['total']"
        :total-last-year-stats="
          stats['visitors']['last_year']['monthly']['total']
        "
        :nl-be-stats="stats['visitors']['current']['monthly']['nl_be']"
        :fr-be-stats="stats['visitors']['current']['monthly']['fr_be']"
        :nl-nl-stats="stats['visitors']['current']['monthly']['nl_nl']"
        :fr-fr-stats="stats['visitors']['current']['monthly']['fr_fr']"
        title="Monthly Visistors"
      />
      <v-layout row style="margin-top: 20px;">
        <v-flex xs6>
          <specific-locale-graph
            v-if="statsLoaded"
            title="BE NL Monthly Visitors"
            :stats="stats['visitors']['current']['monthly']['nl_be']"
            :last-year-stats="
              stats['visitors']['last_year']['monthly']['nl_be']
            "
            first-serie="visitors"
            second-serie="last year visitors"
            :colors="['#FFCD00', '#ffe475']"
          />
        </v-flex>
        <v-flex xs6>
          <specific-locale-graph
            v-if="statsLoaded"
            title="BE FR Monthly Visitors"
            :stats="stats['visitors']['current']['monthly']['fr_be']"
            :last-year-stats="
              stats['visitors']['last_year']['monthly']['fr_be']
            "
            first-serie="visitors"
            second-serie="last year visitors"
            :colors="['#cc0000', '#cc6060']"
          />
        </v-flex>
      </v-layout>
      <v-layout row style="margin-top: 20px;">
        <v-flex xs6>
          <specific-locale-graph
            v-if="statsLoaded"
            title="NL NL Monthly Visitors"
            :stats="stats['visitors']['current']['monthly']['nl_nl']"
            :last-year-stats="
              stats['visitors']['last_year']['monthly']['nl_nl']
            "
            first-serie="visitors"
            second-serie="last year visitors"
            :colors="['#ed9600', '#ebb860']"
          />
        </v-flex>
        <v-flex xs6>
          <specific-locale-graph
            v-if="statsLoaded"
            title="FR FR Monthly Visitors"
            :stats="stats['visitors']['current']['monthly']['fr_fr']"
            :last-year-stats="
              stats['visitors']['last_year']['monthly']['fr_fr']
            "
            first-serie="visitors"
            second-serie="last year visitors"
            :colors="['#2E6FB4', '#6da0d6']"
          />
        </v-flex>
      </v-layout>
    </div>
    <div v-if="graphType === 'CONVERSIONS'">
      <total-data-graph
        class="mb-4"
        v-if="statsLoaded"
        :total-stats="stats['submissions']['current']['daily']['total']"
        :total-last-year-stats="
          stats['submissions']['last_year']['daily']['total']
        "
        :nl-be-stats="stats['submissions']['current']['daily']['nl_be']"
        :fr-be-stats="stats['submissions']['current']['daily']['fr_be']"
        :nl-nl-stats="stats['submissions']['current']['daily']['nl_nl']"
        :fr-fr-stats="stats['submissions']['current']['daily']['fr_fr']"
        title="Daily Conversions"
      />
      <total-data-graph
        class="mb-4"
        v-if="statsLoaded"
        :total-stats="stats['submissions']['current']['monthly']['total']"
        :total-last-year-stats="
          stats['submissions']['last_year']['monthly']['total']
        "
        :nl-be-stats="stats['submissions']['current']['monthly']['nl_be']"
        :fr-be-stats="stats['submissions']['current']['monthly']['fr_be']"
        :nl-nl-stats="stats['submissions']['current']['monthly']['nl_nl']"
        :fr-fr-stats="stats['submissions']['current']['monthly']['fr_fr']"
        title="Monthly Conversions"
      />
      <v-layout row style="margin-top: 20px;">
        <v-flex xs6>
          <specific-locale-graph
            v-if="statsLoaded"
            title="BE NL Monthly Conversions"
            :stats="stats['submissions']['current']['monthly']['nl_be']"
            :last-year-stats="
              stats['submissions']['last_year']['monthly']['nl_be']
            "
            first-serie="conversions"
            second-serie="last year conversions"
            :colors="['#FFCD00', '#ffe475']"
          />
        </v-flex>
        <v-flex xs6>
          <specific-locale-graph
            v-if="statsLoaded"
            title="BE FR Monthly Conversions"
            :stats="stats['submissions']['current']['monthly']['fr_be']"
            :last-year-stats="
              stats['submissions']['last_year']['monthly']['fr_be']
            "
            first-serie="conversions"
            second-serie="last year conversions"
            :colors="['#cc0000', '#cc6060']"
          />
        </v-flex>
      </v-layout>
      <v-layout row style="margin-top: 20px;">
        <v-flex xs6>
          <specific-locale-graph
            v-if="statsLoaded"
            title="NL NL Monthly Conversions"
            :stats="stats['submissions']['current']['monthly']['nl_nl']"
            :last-year-stats="
              stats['submissions']['last_year']['monthly']['nl_nl']
            "
            first-serie="conversions"
            second-serie="last year conversions"
            :colors="['#ed9600', '#ebb860']"
          />
        </v-flex>
        <v-flex xs6>
          <specific-locale-graph
            v-if="statsLoaded"
            title="FR FR Monthly Conversions"
            :stats="stats['submissions']['current']['monthly']['fr_fr']"
            :last-year-stats="
              stats['submissions']['last_year']['monthly']['fr_fr']
            "
            first-serie="conversions"
            second-serie="last year conversions"
            :colors="['#2E6FB4', '#6da0d6']"
          />
        </v-flex>
      </v-layout>
    </div>
    <div v-if="graphType === 'CONVERSION_RATE'">
      <total-data-graph
        class="mb-4"
        v-if="statsLoaded"
        :total-stats="stats['conversion_rate']['current']['daily']['total']"
        :total-last-year-stats="
          stats['conversion_rate']['last_year']['daily']['total']
        "
        :nl-be-stats="stats['conversion_rate']['current']['daily']['nl_be']"
        :fr-be-stats="stats['conversion_rate']['current']['daily']['fr_be']"
        :nl-nl-stats="stats['conversion_rate']['current']['daily']['nl_nl']"
        :fr-fr-stats="stats['conversion_rate']['current']['daily']['fr_fr']"
        title="Daily Conversion Rate"
        is-float
        reference="test1234"
      />
      <total-data-graph
        class="mb-4"
        v-if="statsLoaded"
        :total-stats="stats['conversion_rate']['current']['monthly']['total']"
        :total-last-year-stats="
          stats['conversion_rate']['last_year']['monthly']['total']
        "
        :nl-be-stats="stats['conversion_rate']['current']['monthly']['nl_be']"
        :fr-be-stats="stats['conversion_rate']['current']['monthly']['fr_be']"
        :nl-nl-stats="stats['conversion_rate']['current']['monthly']['nl_nl']"
        :fr-fr-stats="stats['conversion_rate']['current']['monthly']['fr_fr']"
        title="Monthly Conversion Rate"
        is-float
        reference="test123"
      />
      <v-layout row style="margin-top: 20px;">
        <v-flex xs6>
          <specific-locale-graph
            v-if="statsLoaded"
            title="BE NL Monthly Conversion Rate"
            :stats="stats['conversion_rate']['current']['monthly']['nl_be']"
            :last-year-stats="
              stats['conversion_rate']['last_year']['monthly']['nl_be']
            "
            first-serie="conversion rate"
            second-serie="last year conversion rate"
            :colors="['#FFCD00', '#ffe475']"
            is-float
          />
        </v-flex>
        <v-flex xs6>
          <specific-locale-graph
            v-if="statsLoaded"
            title="BE FR Monthly Conversion Rate"
            :stats="stats['conversion_rate']['current']['monthly']['fr_be']"
            :last-year-stats="
              stats['conversion_rate']['last_year']['monthly']['fr_be']
            "
            first-serie="conversion rate"
            second-serie="last year conversion rate"
            :colors="['#cc0000', '#cc6060']"
            is-float
          />
        </v-flex>
      </v-layout>
      <v-layout row style="margin-top: 20px;">
        <v-flex xs6>
          <specific-locale-graph
            v-if="statsLoaded"
            title="NL NL Monthly Conversion Rate"
            :stats="stats['conversion_rate']['current']['monthly']['nl_nl']"
            :last-year-stats="
              stats['conversion_rate']['last_year']['monthly']['nl_nl']
            "
            first-serie="conversion rate"
            second-serie="last year conversion rate"
            :colors="['#ed9600', '#ebb860']"
            is-float
          />
        </v-flex>
        <v-flex xs6>
          <specific-locale-graph
            v-if="statsLoaded"
            title="FR FR Monthly Conversion Rate"
            :stats="stats['conversion_rate']['current']['monthly']['fr_fr']"
            :last-year-stats="
              stats['conversion_rate']['last_year']['monthly']['fr_fr']
            "
            first-serie="conversion rate"
            second-serie="last year conversion rate"
            :colors="['#2E6FB4', '#6da0d6']"
            is-float
          />
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>
<script type="text/babel">
import moment from "moment";
import api from "@/api";
import TotalDataGraph from "./TotalDataGraph";
import Vue from "vue";
import { isNil } from "lodash";
import SpecificLocaleGraph from "./SpecificLocaleGraph";
//visitors
const fetchDailyVisitorsCall = api.websitesStats.fetchDailyVisitorsTimeSeries;
const fetchMonthlyVisitorsCall =
  api.websitesStats.fetchMonthlyVisitorsTimeSeries;
//submissions
const fetchMonthlySubmissionsCall = api.submissionsStats.fetchMonthlyTimeSeries;
const fetchDailySubmissionsCall = api.submissionsStats.fetchDailyTimeSeries;

export default {
  name: "stats-container",
  components: { SpecificLocaleGraph, TotalDataGraph },
  data() {
    return {
      isLoading: false,
      statsLoaded: false,
      graphType: "CONVERSION_RATE",
      stats: {},
      locales: ["total", "nl_be", "fr_be", "nl_nl", "fr_fr"],
      dailyTimeFrameDates: {
        from: moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        lastYearFrom: moment()
          .subtract(30, "days")
          .subtract(1, "years")
          .format("YYYY-MM-DD"),
        lastYearTo: moment()
          .subtract(1, "years")
          .format("YYYY-MM-DD")
      },
      monthlyTimeFrameDates: {
        from: moment()
          .subtract(1, "years")
          .startOf("month")
          .format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        lastYearFrom: moment()
          .subtract(2, "years")
          .startOf("month")
          .format("YYYY-MM-DD"),
        lastYearTo: moment()
          .subtract(1, "years")
          .format("YYYY-MM-DD")
      }
    };
  },
  async created() {
    this.isLoading = true;
    //visitors
    await this.fetchDailyVisitors();
    await this.fetchMonthlyVisitors();
    //submissions
    await this.fetchDailySubmissions();
    await this.fetchMonthlySubmissions();
    //conversion rate
    Vue.set(this.stats, "conversion_rate", []);
    Vue.set(this.stats["conversion_rate"], "current", []);
    Vue.set(this.stats["conversion_rate"], "last_year", []);
    this.calculateConversionRate("daily");
    this.calculateConversionRate("monthly");
    this.statsLoaded = true;
    this.isLoading = false;
  },
  computed: {},
  methods: {
    async fetchDailyVisitors() {
      const visitorTimeSeries = await fetchDailyVisitorsCall({
        date: {
          from: this.dailyTimeFrameDates.from,
          to: this.dailyTimeFrameDates.to
        }
      });

      const lastYearVisitorTimeSeries = await fetchDailyVisitorsCall({
        date: {
          from: this.dailyTimeFrameDates.lastYearFrom,
          to: this.dailyTimeFrameDates.lastYearTo
        }
      });

      this.assignStats("visitors", "current", "daily", visitorTimeSeries);
      this.assignStats(
        "visitors",
        "last_year",
        "daily",
        lastYearVisitorTimeSeries
      );
    },
    async fetchMonthlyVisitors() {
      const visitorTimeSeries = await fetchMonthlyVisitorsCall({
        date: {
          from: this.monthlyTimeFrameDates.from,
          to: this.monthlyTimeFrameDates.to
        }
      });

      const lastYearVisitorTimeSeries = await fetchMonthlyVisitorsCall({
        date: {
          from: this.monthlyTimeFrameDates.lastYearFrom,
          to: this.monthlyTimeFrameDates.lastYearTo
        }
      });

      this.assignStats("visitors", "current", "monthly", visitorTimeSeries);
      this.assignStats(
        "visitors",
        "last_year",
        "monthly",
        lastYearVisitorTimeSeries
      );
    },
    async fetchDailySubmissions() {
      const submissionsTimeSeries = await fetchDailySubmissionsCall({
        date: {
          from: this.dailyTimeFrameDates.from,
          to: this.dailyTimeFrameDates.to
        }
      });

      const lastYearSubmissionsTimeSeries = await fetchDailySubmissionsCall({
        date: {
          from: this.dailyTimeFrameDates.lastYearFrom,
          to: this.dailyTimeFrameDates.lastYearTo
        }
      });

      this.assignStats(
        "submissions",
        "current",
        "daily",
        submissionsTimeSeries
      );
      this.assignStats(
        "submissions",
        "last_year",
        "daily",
        lastYearSubmissionsTimeSeries
      );
    },
    async fetchMonthlySubmissions() {
      const submissionsTimeSeries = await fetchMonthlySubmissionsCall({
        date: {
          from: this.monthlyTimeFrameDates.from,
          to: this.monthlyTimeFrameDates.to
        }
      });

      const lastYearSubmissionsTimeSeries = await fetchMonthlySubmissionsCall({
        date: {
          from: this.monthlyTimeFrameDates.lastYearFrom,
          to: this.monthlyTimeFrameDates.lastYearTo
        }
      });

      this.assignStats(
        "submissions",
        "current",
        "monthly",
        submissionsTimeSeries
      );
      this.assignStats(
        "submissions",
        "last_year",
        "monthly",
        lastYearSubmissionsTimeSeries
      );
    },
    calculateConversionRate(period) {
      Vue.set(this.stats["conversion_rate"]["current"], period, []);
      Vue.set(this.stats["conversion_rate"]["last_year"], period, []);

      this.locales.forEach(locale => {
        Vue.set(
          this.stats["conversion_rate"]["current"][period],
          locale,
          this.getConversionRate(
            this.stats["visitors"]["current"][period][locale],
            this.stats["submissions"]["current"][period][locale]
          )
        );

        Vue.set(
          this.stats["conversion_rate"]["last_year"][period],
          locale,
          this.getConversionRate(
            this.stats["visitors"]["last_year"][period][locale],
            this.stats["submissions"]["last_year"][period][locale]
          )
        );
      });
    },
    assignStats(type, timeFrame, period, data) {
      if (!(type in this.stats)) {
        Vue.set(this.stats, type, []);
      }
      if (!(timeFrame in this.stats[type])) {
        Vue.set(this.stats[type], timeFrame, []);
      }
      Vue.set(this.stats[type][timeFrame], period, []);
      this.locales.forEach(locale => {
        Vue.set(
          this.stats[type][timeFrame][period],
          locale,
          locale in data
            ? data[locale].data.map(data => {
                return { date: data.date, data: data.data };
              })
            : []
        );
      });
    },
    getConversionRate(visitorsArray, submissionsArray) {
      return visitorsArray.map(visitordata => {
        const submissiondata = submissionsArray.filter(
          submissionData => submissionData.date === visitordata.date
        );

        if (isNil(submissiondata)) {
          return {
            date: visitordata.date,
            data: 0.0
          };
        }

        return {
          date: visitordata.date,
          data:
            submissiondata.length > 0
              ? ((submissiondata[0].data / visitordata.data) * 100).toFixed(2)
              : 0.0
        };
      });
    },
    setGraphType(type) {
      this.graphType = type;
    }
  }
};
</script>
