<template>
  <div>
    <dashboard-chart-filters
      v-if="isStoreRegistered"
      store="submissions-chart"
    />
    <vue-apex-charts
      ref="submissionsChart"
      height="300"
      type="line"
      :options="options"
      :series="series"
    />
  </div>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { isNil } from "lodash";
import vueApexCharts from "vue-apexcharts";
const { mapGetters } = createNamespacedHelpers("article");
import ChartMixin from "@/components/mixins/chart/ChartMixin";
import DashboardChartFilters from "../DashboardChartFilters";

export default {
  name: "dashboard-submissions-chart",
  components: {
    DashboardChartFilters,
    vueApexCharts
  },
  mixins: [ChartMixin],
  data() {
    return {
      store: "submissions-chart",
      options: {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
            tools: {
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top"
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: "11px",
            fontFamily: "Helvetica, Arial, sans-serif",
            colors: ["#304758"]
          }
        },
        stroke: {
          width: 2
        },
        tooltip: {
          x: {
            show: false
          }
        },
        title: {
          text: "Submissions data",
          align: "center",
          margin: 10,
          floating: false,
          style: {
            fontSize: "16px",
            color: "#263238"
          }
        },
        xaxis: {
          type: "datetime",
          labels: {
            offsetX: 23
          },
          axisTicks: {
            offsetX: 23
          }
        },
        yaxis: [
          {
            min: 0,
            title: {
              text: "Submissions"
            },
            forceNiceScale: true
          }
        ]
      },
      series: [
        {
          data: []
        }
      ]
    };
  },
  computed: {
    ...mapGetters([])
  },
  watch: {
    data: {
      handler(newVal) {
        this.series = [
          {
            data: []
          }
        ];
        if (newVal.length > 0) {
          if (!isNil(newVal[0].offers)) {
            this.series.filter(function(e) {
              return e.name !== "Sold Submissions";
            });
            this.series.push(
              {
                name: "Submissions",
                data: [],
                type: "bar"
              },
              {
                name: "Offers",
                data: [],
                type: "bar"
              },
              {
                name: "Rejected Offers",
                data: [],
                type: "bar"
              }
            );
          } else {
            this.series.filter(function(e) {
              return e.name !== "Offers" || e.name !== "Rejected Offers";
            });
            this.series.push(
              {
                name: "Submissions",
                data: [],
                type: "bar"
              },
              {
                name: "Sold Submissions",
                data: [],
                type: "bar"
              }
            );
          }
        }
        if (newVal.length > 0) {
          this.series.shift();
          newVal.forEach(e => {
            this.series[0].data.push([
              new Date(e.time).getTime(),
              e.submissions
            ]);
            if (!isNil(e.offers)) {
              this.series[1].data.push([new Date(e.time).getTime(), e.offers]);
              this.series[2].data.push([
                new Date(e.time).getTime(),
                e.rejectedOffers
              ]);
            } else {
              this.series[1].data.push([
                new Date(e.time).getTime(),
                e.soldSubmissions
              ]);
            }
          });
        }
      },
      deep: true
    }
  }
};
</script>
<style scoped></style>
