<template>
  <div>
    <company-subjects-chart-filters v-if="isStoreRegistered" />
    <v-container>
      <div class="flex-container">
        <div
          class="lg5-custom"
          v-for="(subject, index) in subjects"
          :key="index"
        >
          <v-sheet style="margin-bottom: 10px;" color="#F5F5F5">
            <v-layout style="padding: 10px" column>
              <h3
                style="text-align: center; margin-bottom: 15px; font-size: 10pt;"
              >
                {{ subject.subject }}
              </h3>
              <p style="margin-left: 10px;">
                Offers: <b>{{ subject.offers }}</b>
              </p>
              <p style="margin-left: 10px;">
                Rejected offers: <b>{{ subject.rejectedOffers }}</b>
              </p>
            </v-layout>
          </v-sheet>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script type="text/babel">
import ChartMixin from "@/components/mixins/chart/ChartMixin";
import CompanySubjectsChartFilters from "@/views/dashboard/Submissions/CompanySubjectsChartFilters";
import { isNil } from "lodash";

export default {
  name: "dashboard-company-submissions-chart",
  components: {
    CompanySubjectsChartFilters
  },
  mixins: [ChartMixin],
  data() {
    return {
      store: "company-subjects-chart"
    };
  },
  computed: {
    subjects() {
      if (isNil(this.data)) {
        return [];
      } else {
        return this.data.subjects;
      }
    }
  }
};
</script>
<style scoped>
.company-lead-overview-container {
  padding: 24px;
}

.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.lg5-custom {
  width: 23%;
  margin: 10px;
}
</style>
