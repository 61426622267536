var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-overview',{attrs:{"store":"incidents","title":"Incidents","icon":"far fa-fw fa-bell","headers":[
      { text: 'Id', value: 'id', type: 'id' },
      { text: 'Name', value: 'name', sortable: false },
      { text: 'Severity', value: 'severity', sortable: false },
      { text: 'Created at', value: 'created_at', type: 'date' },
      { text: 'Subject', value: 'subjcet', sortable: false },
      { text: 'Acknowledged', value: 'Acknowledged', sortable: false }
    ],"force-initial-sort":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
    var item = ref.item;
return _c('incidents-table-row',{attrs:{"incident-id":item}})}}])},[_c('incidents-table-filter',{attrs:{"slot":"filters"},slot:"filters"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }