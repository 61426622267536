<template>
  <v-card style="background-color: white; margin-top: 20px;">
    <v-container>
      <v-card>
        <div style="height: 200px">
          <vue-apex-charts
            ref="fallbackSubmissionsChart"
            height="100%"
            type="line"
            :options="options"
            :series="series"
          />
        </div>
        <v-layout
          justify-space-between
          style="padding-left: 20px; padding-right: 20px"
        >
          <v-flex xs3>
            Average fallback submissions: <br />
            <b style="font-size: 18px;">{{ averageFallbackSubmissions }}</b>
          </v-flex>
        </v-layout>
      </v-card>
    </v-container>
  </v-card>
</template>
<script type="text/babel">
import moment from "moment";
import api from "@/api";
import { isNil } from "lodash";
import vueApexCharts from "vue-apexcharts";
const fetchFallbackSubmissionsCall =
  api.submissionsStats.fetchFallbackTimeSeries;

export default {
  name: "fallback-submissions",
  components: {
    vueApexCharts
  },
  data() {
    return {
      fallbackSubmissions: [],
      averageFallbackSubmissions: "",
      options: {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          background: "#f5f5f5"
        },
        stroke: {
          width: 2
        },
        title: {
          text: "Fallback rate",
          align: "center",
          margin: 30,
          offsetY: 20,
          floating: true,
          style: {
            fontSize: "16px"
          }
        },
        colors: ["#FFCD00", "#2E6FB4", "#000000"],
        xaxis: {
          type: "datetime",
          labels: {
            format: "MMM"
          }
        },
        yaxis: [
          {
            show: true,
            seriesName: "Fallback Submissions",
            decimalsInFloat: false,
            tickAmount: 5,
            min: 0
          },
          {
            show: false,
            seriesName: "Fallback Submissions",
            decimalsInFloat: false,
            tickAmount: 5,
            min: 0
          },
          {
            show: false,
            seriesName: "Fallback rate",
            tickAmount: 5,
            min: 0,
            labels: {
              show: true,
              formatter: value => {
                return !isNil(value) ? `${value.toFixed(2)}%` : value;
              }
            }
          }
        ]
      },
      series: [
        {
          name: "Fallback Submissions",
          type: "line",
          data: []
        },
        {
          name: "Non Fallback Submissions",
          type: "line",
          data: []
        },
        {
          name: "Fallback rate",
          type: "line",
          data: []
        }
      ]
    };
  },
  created() {
    this.fetch();
  },
  watch: {
    fallbackSubmissions: {
      handler(newVal) {
        this.series.map(serie => (serie.data = []));
        newVal.forEach(e => {
          this.series[0].data.push([
            new Date(e.date).getTime(),
            e.fallbackCount
          ]);
          this.series[1].data.push([
            new Date(e.date).getTime(),
            e.submission_count
          ]);
          this.series[2].data.push([
            new Date(e.date).getTime(),
            (e.fallbackCount / (e.fallbackCount + e.submission_count)) * 100
          ]);
        });
        this.$refs.fallbackSubmissionsChart.refresh();
      },
      deep: true
    }
  },
  methods: {
    async fetch() {
      const now = moment().format("YYYY-MM-DD");
      let start = null;
      if (moment().diff("2019-10-01", "years") > 12) {
        start = moment()
          .subtract(1, "years")
          .startOf("month")
          .format("YYYY-MM-DD");
      } else {
        start = "2019-07-01";
      }

      this.fallbackSubmissions = await fetchFallbackSubmissionsCall({
        date: { from: start, to: now }
      });

      this.averageFallbackSubmissions = this.getAvg(
        this.fallbackSubmissions
          .map(timeframe => {
            return moment(timeframe.date).isBefore("2019-10-01")
              ? null
              : timeframe.fallbackCount;
          })
          .filter(timeframe => {
            return timeframe !== null;
          })
      );
    },
    getAvg(grades) {
      grades.pop();
      const total = grades.reduce((acc, c) => acc + c, 0);
      return (total / grades.length).toFixed(0);
    }
  }
};
</script>
