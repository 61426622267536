<template>
  <tr>
    <td>{{ incident.id }}</td>
    <td>{{ incident.name }}</td>
    <td>{{ incident.severity }}</td>
    <td>
      {{ incident.created_at | formatDate }}
    </td>
    <td>
      <router-link :to="subjectLink">{{ $t("subject") }}</router-link>
    </td>
    <td>
      <span v-if="incident.acknowledged_by">
        by {{ incident.acknowledged_by }}
      </span>
      <span v-else>
        <a v-on:click="acknowledge(incident.id)">{{ $t("acknowledge") }}</a>
      </span>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters } = createNamespacedHelpers("incidents");
export default {
  name: "indicents-table-row",
  components: {},
  props: {
    incidentId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters([FIND_BY_ID]),
    incident() {
      return this[FIND_BY_ID](this.incidentId);
    },
    subjectLink() {
      switch (this.incident.subject_type) {
        case "App\\Entities\\Article":
          return {
            name: "articles.show",
            params: {
              articleId: this.incident.subject_id
            }
          };
        default:
          return {
            to: "dashboard"
          };
      }
    }
  },
  methods: {
    acknowledge(acknowledgeId) {
      this.$store.dispatch("incidents/acknowledge", acknowledgeId);
    }
  }
};
</script>
