<template>
  <div v-if="hasRolesOrPermissions('dashboard_view')" class="container-fluid">
    <div class="row">
      <div class="col no-float">
        <incidents></incidents>
        <v-card>
          <dashboard-submissions-chart />
        </v-card>
        <v-card style="margin-top: 30px;">
          <dashboard-submissions-comparison-chart v-if="comparisonChart" />
          <v-layout justify-center>
            <v-btn @click="showComparisonChart()">
              {{ comparisonBtnText }}
            </v-btn>
          </v-layout>
        </v-card>
        <v-card class="my-4">
          <dashboard-company-submissions-chart v-if="companySubjectsCharts" />
          <v-layout justify-center>
            <v-btn @click="showCompanySubjectsCharts()">
              {{ companySubjectsBtnText }}
            </v-btn>
          </v-layout>
        </v-card>
        <!--        <daily-submissions />-->
        <!--        <monthly-submissions />-->
        <!--        <daily-visitors />-->
        <!--        <monthly-visitors />-->

        <stats-container />
        <fallback-submissions />
      </div>
    </div>
  </div>
</template>
<script>
import Incidents from "@/components/incidents/Incidents";
// import MonthlySubmissions from "@/views/dashboard/Graphs/Submissions/MonthlySubmissions";
// import DailySubmissions from "@/views/dashboard/Graphs/Submissions/DailySubmissions";
// import MonthlyVisitors from "@/views/dashboard/Graphs/Websites/MonthlyVisitors";
// import DailyVisitors from "@/views/dashboard/Graphs/Websites/DailyVisitors";
import DashboardSubmissionsComparisonChart from "./Submissions/DashboardComparisonSubmissionsChart";
import DashboardSubmissionsChart from "./Submissions/DashboardSubmissionsChart";
import DashboardCompanySubmissionsChart from "./Submissions/DashboardCompanySubjectsCharts";
import FallbackSubmissions from "@/views/dashboard/Graphs/Submissions/FallbackSubmissions";
import StatsContainer from "./Graphs/General/StatsContainer";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");

export default {
  name: "dashboard",
  components: {
    StatsContainer,
    FallbackSubmissions,
    DashboardCompanySubmissionsChart,
    DashboardSubmissionsChart,
    DashboardSubmissionsComparisonChart,
    //DailyVisitors,
    //MonthlyVisitors,
    //DailySubmissions,
    //MonthlySubmissions,
    Incidents
  },
  data() {
    return {
      comparisonChart: false,
      companySubjectsCharts: false
    };
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    comparisonBtnText() {
      return this.comparisonChart
        ? "Hide comparison chart"
        : "Show comparison chart";
    },
    companySubjectsBtnText() {
      return this.comparisonChart
        ? "Hide company subject charts"
        : "Show company subject charts";
    }
  },
  methods: {
    showComparisonChart() {
      this.comparisonChart = !this.comparisonChart;
    },
    showCompanySubjectsCharts() {
      this.companySubjectsCharts = !this.companySubjectsCharts;
    }
  }
};
</script>
