<template>
  <div>
    <v-progress-linear
      :indeterminate="loading"
      background-color="white"
    ></v-progress-linear>
    <v-layout row justify-center>
      <v-flex xs3>
        <v-autocomplete-company v-model="company" customer />
      </v-flex>
      <v-flex xs3>
        <v-autocomplete-filter
          v-model="filters"
          :companies="company"
          multiple
        />
      </v-flex>
      <v-flex xs2>
        <v-select
          v-model="year"
          :items="years"
          label="Select year"
          single-line
        ></v-select>
      </v-flex>
      <v-flex xs2>
        <v-select
          v-model="month"
          :items="months"
          item-text="name"
          item-value="nr"
          label="Select month"
          single-line
        ></v-select>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { FILTERS, IS_FETCHABLE } from "@/store/templates/chart/getter-types";
import {
  SET_FILTER,
  SET_IS_FETCHABLE
} from "@/store/templates/chart/mutation-types";
import { INIT } from "@/store/templates/chart/action-types";
import { debounce, isNil } from "lodash";
import VAutocompleteCompany from "@/components/company/VAutocompleteCompany";
import VAutocompleteFilter from "@/components/filter/VAutocompleteFilter";

export default {
  name: "company-subjects-chart-filters",
  components: {
    VAutocompleteFilter,
    VAutocompleteCompany
  },
  data: () => ({
    timeframe: "ALL",
    year: null,
    month: null,
    months: [
      {
        name: "January",
        nr: 1
      },
      {
        name: "February",
        nr: 2
      },
      {
        name: "March",
        nr: 3
      },
      {
        name: "April",
        nr: 4
      },
      {
        name: "May",
        nr: 5
      },
      {
        name: "June",
        nr: 6
      },
      {
        name: "July",
        nr: 7
      },
      {
        name: "August",
        nr: 8
      },
      {
        name: "September",
        nr: 9
      },
      {
        name: "October",
        nr: 10
      },
      {
        name: "November",
        nr: 11
      },
      {
        name: "December",
        nr: 12
      }
    ]
  }),
  computed: {
    company: {
      get() {
        return this.$store.getters[`company-subjects-chart/${FILTERS}`].company;
      },
      set(newVal) {
        if (!isNil(newVal)) {
          this.$store.commit(`company-subjects-chart/${SET_FILTER}`, {
            property: "company",
            value: newVal
          });
          if (!isNil(this.month) && !isNil(this.year)) {
            this.$store.commit(
              `company-subjects-chart/${SET_IS_FETCHABLE}`,
              true
            );
          }
        }
      }
    },
    filters: {
      get() {
        return this.$store.getters[`company-subjects-chart/${FILTERS}`].filters;
      },
      set(newVal) {
        this.$store.commit(`company-subjects-chart/${SET_FILTER}`, {
          property: "filters",
          value: newVal
        });
      }
    },
    loading() {
      return this.$store.getters["company-subjects-chart/isLoading"];
    },
    years() {
      const currentYear = new Date().getFullYear();
      let startYear = 2018;
      let years = [];
      while (startYear <= currentYear) {
        years.push(startYear++);
      }
      return years;
    },
    isFetchable() {
      return this.$store.getters[`company-subjects-chart/${IS_FETCHABLE}`];
    }
  },
  watch: {
    company() {
      this.debouncedSearch(this.getChart);
    },
    filters() {
      if (!isNil(this.company) && !isNil(this.month) && !isNil(this.year)) {
        this.debouncedSearch(this.getChart);
      }
    },
    month(newVal) {
      if (!isNil(this.year)) {
        const firstDay = new Date(this.year, newVal - 1, 2)
          .toISOString()
          .substr(0, 10);
        const lastDay = new Date(this.year, newVal).toISOString().substr(0, 10);

        const date = {
          from: firstDay,
          to: lastDay
        };
        this.$store.commit(`company-subjects-chart/${SET_FILTER}`, {
          property: "date",
          value: date
        });
        if (!isNil(this.company)) {
          this.$store.commit(
            `company-subjects-chart/${SET_IS_FETCHABLE}`,
            true
          );
          this.debouncedSearch(this.getChart);
        }
      }
    },
    year(newVal) {
      if (!isNil(this.month)) {
        const firstDay = new Date(newVal, this.month - 1, 2)
          .toISOString()
          .substr(0, 10);
        const lastDay = new Date(newVal, this.month)
          .toISOString()
          .substr(0, 10);

        const date = {
          from: firstDay,
          to: lastDay
        };
        this.$store.commit(`company-subjects-chart/${SET_FILTER}`, {
          property: "date",
          value: date
        });
        if (!isNil(this.company)) {
          this.$store.commit(
            `company-subjects-chart/${SET_IS_FETCHABLE}`,
            true
          );
          this.debouncedSearch(this.getChart);
        }
      }
    }
  },
  methods: {
    debouncedSearch: debounce(searchMethod => {
      searchMethod();
    }, 750),
    getChart() {
      if (this.isFetchable) {
        this.$store.dispatch(`company-subjects-chart/${INIT}`);
      }
    }
  }
};
</script>
