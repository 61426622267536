<template>
  <v-card>
    <div style="height: 200px">
      <vue-apex-charts
        :ref="reference"
        height="100%"
        type="line"
        :options="options"
        :series="series"
      />
    </div>
    <v-layout
      justify-space-between
      style="padding-left: 20px; padding-right: 20px"
    >
      <v-flex xs3>
        Average: <br />
        <b style="font-size: 18px;">{{ averageData }}</b>
      </v-flex>
      <v-flex xs3 style="text-align: end; color: gray;">
        Average last year: <br />
        <b style="font-size: 18px;">{{ lastYearAverageData }}</b>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script type="text/babel">
import vueApexCharts from "vue-apexcharts";
import { isNil, cloneDeep } from "lodash";
import moment from "moment";

export default {
  name: "total-data-graph",
  components: {
    vueApexCharts
  },
  props: {
    totalStats: {
      type: Array,
      default: () => []
    },
    totalLastYearStats: {
      type: Array,
      default: () => []
    },
    nlBeStats: {
      type: Array,
      default: () => []
    },
    frBeStats: {
      type: Array,
      default: () => []
    },
    nlNlStats: {
      type: Array,
      default: () => []
    },
    frFrStats: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      required: true
    },
    isFloat: {
      type: Boolean,
      default: false
    },
    reference: {
      type: String,
      default: "totalDataChart"
    }
  },
  data() {
    return {
      averageData: null,
      lastYearAverageData: null,
      series: [
        {
          name: "Total",
          type: "line",
          data: []
        },
        {
          name: "Total Last year",
          type: "line",
          data: []
        },
        {
          name: "BE NL",
          type: "line",
          data: []
        },
        {
          name: "BE FR",
          type: "line",
          data: []
        },
        {
          name: "NL NL",
          type: "line",
          data: []
        },
        {
          name: "FR FR",
          type: "line",
          data: []
        }
      ]
    };
  },
  watch: {
    totalStats: {
      handler(newVal) {
        this.averageData = this.getAvg(
          newVal.map(data =>
            this.isFloat ? parseFloat(data.data) : parseInt(data.data)
          )
        );
        this.series[0].data = [];
        newVal.forEach(e => {
          this.series[0].data.push([new Date(e.date).getTime(), e.data]);
        });
      },
      deep: true,
      immediate: true
    },
    nlBeStats: {
      handler(newVal) {
        this.series[2].data = [];
        newVal.forEach(e => {
          this.series[2].data.push([new Date(e.date).getTime(), e.data]);
        });
      },
      deep: true,
      immediate: true
    },
    frBeStats: {
      handler(newVal) {
        this.series[3].data = [];
        newVal.forEach(e => {
          this.series[3].data.push([new Date(e.date).getTime(), e.data]);
        });
      },
      deep: true,
      immediate: true
    },
    nlNlStats: {
      handler(newVal) {
        this.series[4].data = [];
        newVal.forEach(e => {
          this.series[4].data.push([new Date(e.date).getTime(), e.data]);
        });
      },
      deep: true,
      immediate: true
    },
    frFrStats: {
      handler(newVal) {
        this.series[5].data = [];
        newVal.forEach(e => {
          this.series[5].data.push([new Date(e.date).getTime(), e.data]);
        });
      },
      deep: true,
      immediate: true
    },
    totalLastYearStats: {
      handler(newVal) {
        this.lastYearAverageData = this.getAvg(
          newVal.map(data =>
            this.isFloat ? parseFloat(data.data) : parseInt(data.data)
          )
        );
        let mappedVal = cloneDeep(newVal);
        mappedVal = mappedVal.map(data => {
          data.date = moment(data.date)
            .add(1, "years")
            .format("YYYY-MM-DD");
          return data;
        });
        this.series[1].data = [];

        this.series[0].data.forEach(data => {
          const equivalentLastYear = mappedVal.filter(lastYear => {
            return data[0] === new Date(lastYear.date).getTime();
          })[0];
          if (!isNil(equivalentLastYear)) {
            this.series[1].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.data
            ]);
          } else {
            this.series[1].data.push([data[0], 0.0]);
          }
        });
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    options() {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          background: "#f5f5f5"
        },
        stroke: {
          width: 2,
          dashArray: [0, 8]
        },
        title: {
          text: this.title,
          align: "center",
          margin: 30,
          offsetY: 20,
          floating: true,
          style: {
            fontSize: "16px"
          }
        },
        colors: [
          "#000000",
          "#7d7d7d",
          "#FFCD00",
          "#cc0000",
          "#ed9600",
          "#2E6FB4"
        ],
        xaxis: {
          type: "datetime"
        },
        yaxis: [
          {
            show: true,
            tickAmount: 5,
            min: 0,
            labels: {
              formatter: value => {
                if (isNil(value)) {
                  return value;
                }
                return this.isFloat ? value.toFixed(2) : value.toFixed(0);
              }
            }
          }
        ]
      };
    }
  },
  methods: {
    round(rate) {
      return !isNil(rate) ? rate.toFixed(2) : rate;
    },
    disableLegend(legend) {
      const chart = this.$refs[this.reference];
      if (!isNil(chart)) {
        chart.toggleSeries(legend);
      }
    },
    getAvg(grades) {
      grades.pop();
      const total = grades.reduce((acc, c) => acc + c, 0);
      return (total / grades.length).toFixed(this.isFloat ? 2 : 0);
    }
  }
};
</script>
<style>
.custom-tooltip {
  background-color: white;
  padding: 10px;
}
.toolbar-text-info {
  font-weight: bold;
}
.positive-text {
  color: green;
  font-weight: bold;
  margin-left: 10px;
}
.negative-text {
  color: red;
  font-weight: bold;
  margin-left: 10px;
}
.last-year-stat {
  font-size: 11px;
  font-weight: 100;
  color: #878787;
}
</style>
