<template>
  <v-card>
    <div style="height: 200px">
      <vue-apex-charts
        ref="articleChart"
        height="100%"
        type="line"
        :options="options"
        :series="series"
      />
    </div>
    <v-layout
      justify-space-between
      style="padding-left: 20px; padding-right: 20px"
    >
      <v-flex xs3>
        Average: <br />
        <b style="font-size: 18px;">{{ averageData }}</b>
      </v-flex>
      <v-flex xs3 style="text-align: end; color: gray;">
        Average last year: <br />
        <b style="font-size: 18px;">{{ lastYearAverageData }}</b>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script type="text/babel">
import vueApexCharts from "vue-apexcharts";
import { isNil, cloneDeep } from "lodash";
import moment from "moment";

export default {
  name: "specific-locale-graph",
  components: {
    vueApexCharts
  },
  props: {
    stats: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      required: true
    },
    lastYearStats: {
      type: Array,
      default: () => []
    },
    firstSerie: {
      type: String,
      required: true
    },
    secondSerie: {
      type: String,
      required: true
    },
    colors: {
      type: Array,
      default: () => []
    },
    isFloat: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      averageData: null,
      lastYearAverageData: null,
      series: [
        {
          name: this.firstSerie,
          type: "line",
          data: []
        },
        {
          name: this.secondSerie,
          type: "line",
          data: []
        }
      ]
    };
  },
  watch: {
    stats: {
      handler(newVal) {
        this.averageData = this.getAvg(
          newVal.map(data =>
            this.isFloat ? parseFloat(data.data) : parseInt(data.data)
          )
        );
        this.series.forEach(e => {
          e.data = [];
        });
        newVal.forEach(e => {
          this.series[0].data.push([new Date(e.date).getTime(), e.data]);
        });
      },
      deep: true,
      immediate: true
    },
    lastYearStats: {
      handler(newVal) {
        this.lastYearAverageData = this.getAvg(
          newVal.map(data =>
            this.isFloat ? parseFloat(data.data) : parseInt(data.data)
          )
        );
        let mappedVal = cloneDeep(newVal);
        mappedVal = mappedVal.map(data => {
          data.date = moment(data.date)
            .add(1, "years")
            .format("YYYY-MM");
          return data;
        });
        this.series[1].data = [];

        this.series[0].data.forEach(data => {
          const equivalentLastYear = mappedVal.filter(lastYear => {
            return data[0] === new Date(lastYear.date).getTime();
          })[0];
          if (!isNil(equivalentLastYear)) {
            this.series[1].data.push([
              new Date(equivalentLastYear.date).getTime(),
              equivalentLastYear.data
            ]);
          } else {
            this.series[1].data.push([data[0], 0.0]);
          }
        });
      },
      deep: true,
      immediate: true
    },
    annotations: {
      handler(newVal) {
        this.setAnnotations(newVal);
      },
      deep: true
    }
  },
  computed: {
    options() {
      return {
        chart: {
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          background: "#f5f5f5"
        },
        stroke: {
          width: 2,
          dashArray: [0, 8]
        },
        title: {
          text: this.title,
          align: "center",
          margin: 30,
          offsetY: 20,
          floating: true,
          style: {
            fontSize: "16px"
          }
        },
        tooltip: {
          // eslint-disable-next-line
          custom: function({ series, seriesIndex, dataPointIndex, w }) {
            const valueDiff =
              series[0][dataPointIndex] - series[1][dataPointIndex];

            const persentageDiff = (
              (valueDiff / series[0][dataPointIndex]) *
              100
            ).toFixed(2);
            let isPositive = false;
            if (valueDiff > 0) {
              isPositive = true;
            } else {
              isPositive = false;
            }
            return (
              '<div class="custom-tooltip">' +
              '<span class="toolbar-text-info">' +
              series[0][dataPointIndex] +
              (isPositive
                ? '<span class="positive-text">'
                : '<span class="negative-text">') +
              (isPositive ? " +" : " -") +
              persentageDiff +
              "%" +
              "</span>" +
              '<span class="last-year-stat">' +
              " (" +
              series[1][dataPointIndex] +
              ")" +
              "</span>" +
              "</span>" +
              "</div>"
            );
          }
        },
        colors: this.colors,
        xaxis: {
          type: "datetime",
          labels: {
            format: "MMM"
          }
        },
        yaxis: [
          {
            show: true,
            //forceNiceScale: true,
            tickAmount: 5,
            min: 0,
            labels: {
              formatter: value => {
                if (isNil(value)) {
                  return value;
                }
                return this.isFloat ? value.toFixed(2) : value.toFixed(0);
              }
            }
          }
          // {
          //   show: false,
          //   //forceNiceScale: true,
          //   tickAmount: 5,
          //   labels: {
          //     style: {
          //       colors: "#fff"
          //     },
          //     formatter: value => {
          //       return `${this.round(value)}%`;
          //     },
          //     minWidth: 0
          //   },
          //   min: 0
          // }
        ]
      };
    }
  },
  methods: {
    round(rate) {
      return !isNil(rate) ? rate.toFixed(2) : rate;
    },
    getAvg(grades) {
      grades.pop();
      const total = grades.reduce((acc, c) => acc + c, 0);
      return (total / grades.length).toFixed(this.isFloat ? 2 : 0);
    }
  }
};
</script>
<style>
.custom-tooltip {
  background-color: white;
  padding: 10px;
}
.toolbar-text-info {
  font-weight: bold;
}
.positive-text {
  color: green;
  font-weight: bold;
  margin-left: 10px;
}
.negative-text {
  color: red;
  font-weight: bold;
  margin-left: 10px;
}
.last-year-stat {
  font-size: 11px;
  font-weight: 100;
  color: #878787;
}
</style>
