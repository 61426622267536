<template>
  <div>
    <table-overview
      store="incidents"
      title="Incidents"
      icon="far fa-fw fa-bell"
      :headers="[
        { text: 'Id', value: 'id', type: 'id' },
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Severity', value: 'severity', sortable: false },
        { text: 'Created at', value: 'created_at', type: 'date' },
        { text: 'Subject', value: 'subjcet', sortable: false },
        { text: 'Acknowledged', value: 'Acknowledged', sortable: false }
      ]"
      force-initial-sort
    >
      <incidents-table-row
        slot="table-row"
        slot-scope="{ item }"
        :incident-id="item"
      />
      <incidents-table-filter slot="filters" />
    </table-overview>
  </div>
</template>

<script type="text/babel">
import IncidentsTableRow from "@/components/incidents/IncidentsTableRow";
import IncidentsTableFilter from "@/components/incidents/IncidentsTableFilter";
import TableOverview from "@/components/generic/overview/TableOverview";
import IncidentsModule from "@/store/modules/incidents";
import { mapOverviewLifecycleMethods } from "@/views/helpers";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "incidents",
  IncidentsModule
);

export default {
  name: "incidents-overview",
  components: {
    TableOverview,
    IncidentsTableFilter,
    IncidentsTableRow
  },
  ...overviewLifecycleMethods,
  metaInfo: () => ({
    title: `incidents`
  })
};
</script>
