<template>
  <v-form>
    <v-radio-group v-model="acknowledged">
      <v-radio :label="$t('all')" :value="null"></v-radio>
      <v-radio :label="$t('acknowledged')" value="true"></v-radio>
      <v-radio :label="$t('not_acknowledged')" value="false"></v-radio>
    </v-radio-group>
    <v-select
      item-text="text"
      item-value="value"
      v-model="severity"
      :items="severities"
      label="Severity"
    ></v-select>
  </v-form>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";

const { mapComputedFilters } = createNamespacedHelpers("incidents");

export default {
  name: "incidents-table-filter",
  components: {},
  data: () => ({
    severities: [
      {
        text: "All",
        value: null
      },
      {
        text: "Emergency",
        value: "emerg"
      },
      {
        text: "Alert",
        value: "alert"
      },
      {
        text: "Critical",
        value: "crit"
      },
      {
        text: "Error",
        value: "err"
      },
      {
        text: "Warning",
        value: "warning"
      },
      {
        text: "Notice",
        value: "notice"
      },
      {
        text: "Info",
        value: "info"
      },
      {
        text: "Debug",
        value: "debug"
      }
    ]
  }),
  computed: {
    ...mapComputedFilters(["acknowledged", "severity"])
  }
};
</script>
