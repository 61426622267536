<template>
  <div>
    <v-progress-linear
      :indeterminate="loading"
      background-color="white"
    ></v-progress-linear>
    <v-container style="padding-top: 0px">
      <v-layout row justify-end>
        <v-flex xs2>
          <v-select-locale v-model="locale" multiple clearable />
        </v-flex>
        <v-flex xs3>
          <v-autocomplete-subject v-model="subjects" multiple />
        </v-flex>
        <v-flex style="max-width: 14%; margin-top: 12px;" xs2 justify-end>
          <v-btn-toggle v-model="timeframe" depressed>
            <v-btn value="7D" color="teal lighten-2">
              7D
            </v-btn>
            <v-btn value="30D" color="teal lighten-2">
              30D
            </v-btn>
            <v-btn value="3M" color="teal lighten-2">
              3M
            </v-btn>
            <v-btn value="12M" color="teal lighten-2">
              12M
            </v-btn>
            <v-btn value="ALL" color="teal lighten-2">
              ALL
            </v-btn>
          </v-btn-toggle>
        </v-flex>
        <v-flex xs4>
          <between-date-input v-model="date" />
        </v-flex>
      </v-layout>
      <v-layout row justify-end>
        <v-flex xs3>
          <v-autocomplete-website
            v-model="websites"
            multiple
            item-text="host"
          />
        </v-flex>
        <v-flex xs1>
          <v-radio-group v-model="radioGroup">
            <v-radio
              v-for="companyOption in companyOptions"
              :key="companyOption"
              :label="companyOption"
              :value="companyOption"
            ></v-radio>
          </v-radio-group>
        </v-flex>
        <v-flex xs3>
          <v-autocomplete-company
            v-model="companies"
            multiple
            :reseller="isResellerSelected"
            :customer="isCustomerSelected"
          />
        </v-flex>
        <v-flex xs3>
          <v-autocomplete-filter
            v-model="filters"
            :companies="companies"
            multiple
          />
        </v-flex>
        <v-flex xs1>
          <v-btn color="primary" @click="getChart">Reload</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import BetweenDateInput from "../../components/generic/input/dates/BetweenDateInput";
import { FILTERS } from "@/store/templates/chart/getter-types";
import { SET_FILTER } from "@/store/templates/chart/mutation-types";
import { INIT } from "@/store/templates/chart/action-types";
import VAutocompleteSubject from "@/components/subject/VAutocompleteSubject";
import VSelectLocale from "@/components/locale/VSelectLocale";
import { debounce } from "lodash";
import VAutocompleteCompany from "@/components/company/VAutocompleteCompany";
import VAutocompleteFilter from "@/components/filter/VAutocompleteFilter";
import VAutocompleteWebsite from "@/components/website/VAutocompleteWebsite";

export default {
  name: "dashboard-chart-filters",
  components: {
    VAutocompleteWebsite,
    VAutocompleteFilter,
    VAutocompleteCompany,
    VSelectLocale,
    VAutocompleteSubject,
    BetweenDateInput
  },
  props: {
    store: {
      type: String,
      required: true
    }
  },
  data: () => ({
    timeframe: "ALL",
    radioGroup: "customer",
    companyOptions: ["Customers", "Resellers"],
    isCustomerSelected: true,
    isResellerSelected: false
  }),
  computed: {
    date: {
      get() {
        return this.$store.getters[`${this.store}/${FILTERS}`].date;
      },
      set(newVal) {
        this.$store.commit(`${this.store}/${SET_FILTER}`, {
          property: "date",
          value: newVal
        });
      }
    },
    subjects: {
      get() {
        return this.$store.getters[`${this.store}/${FILTERS}`].subjects;
      },
      set(newVal) {
        this.$store.commit(`${this.store}/${SET_FILTER}`, {
          property: "subjects",
          value: newVal
        });
      }
    },
    companies: {
      get() {
        return this.$store.getters[`${this.store}/${FILTERS}`].companies;
      },
      set(newVal) {
        this.$store.commit(`${this.store}/${SET_FILTER}`, {
          property: "companies",
          value: newVal
        });
      }
    },
    filters: {
      get() {
        return this.$store.getters[`${this.store}/${FILTERS}`].filters;
      },
      set(newVal) {
        this.$store.commit(`${this.store}/${SET_FILTER}`, {
          property: "filters",
          value: newVal
        });
      }
    },
    websites: {
      get() {
        return this.$store.getters[`${this.store}/${FILTERS}`].websites;
      },
      set(newVal) {
        this.$store.commit(`${this.store}/${SET_FILTER}`, {
          property: "websites",
          value: newVal
        });
      }
    },
    locale: {
      get() {
        return this.$store.getters[`${this.store}/${FILTERS}`].locale;
      },
      set(newVal) {
        this.$store.commit(`${this.store}/${SET_FILTER}`, {
          property: "locale",
          value: newVal
        });
      }
    },
    loading() {
      return this.$store.getters[`${this.store}/isLoading`];
    }
  },
  watch: {
    timeframe(newVal) {
      const date = this.getDateForTimeframe(newVal);
      this.$store.commit(`${this.store}/${SET_FILTER}`, {
        property: "date",
        value: date
      });
      //debounce(this.getChart, 250);
    },
    locale() {
      this.debouncedSearch(this.getChart);
    },
    subjects() {
      this.debouncedSearch(this.getChart);
    },
    date() {
      this.debouncedSearch(this.getChart);
    },
    companies() {
      this.debouncedSearch(this.getChart);
    },
    filters() {
      this.debouncedSearch(this.getChart);
    },
    radioGroup(newVal) {
      if (newVal === "Customers") {
        this.isCustomerSelected = true;
        this.isResellerSelected = false;
      } else {
        this.isCustomerSelected = false;
        this.isResellerSelected = true;
      }
    }
  },
  methods: {
    debouncedSearch: debounce(searchMethod => {
      searchMethod();
    }, 750),
    getChart() {
      this.$store.dispatch(`${this.store}/${INIT}`);
    },
    getDateForTimeframe(timeframe) {
      const currentDate = new Date();
      let date = {
        from: null,
        to: null
      };
      switch (timeframe) {
        case "7D":
          date.from = new Date(new Date().setDate(new Date().getDate() - 7))
            .toISOString()
            .substr(0, 10);
          date.to = currentDate.toISOString().substr(0, 10);
          break;
        case "30D":
          date.from = new Date(new Date().setDate(new Date().getDate() - 30))
            .toISOString()
            .substr(0, 10);
          date.to = currentDate.toISOString().substr(0, 10);
          break;
        case "3M":
          date.from = new Date(new Date().setDate(new Date().getDate() - 90));
          date.from = new Date(date.from.getFullYear(), date.from.getMonth(), 2)
            .toISOString()
            .substr(0, 10);
          date.to = currentDate.toISOString().substr(0, 10);
          break;
        case "12M":
          date.from = new Date(new Date().setDate(new Date().getDate() - 365));
          date.from = new Date(date.from.getFullYear(), date.from.getMonth(), 2)
            .toISOString()
            .substr(0, 10);
          date.to = currentDate.toISOString().substr(0, 10);
          break;
        case "ALL":
          date.from = new Date("2018-02-01").toISOString().substr(0, 10);
          date.to = currentDate.toISOString().substr(0, 10);
          break;
        default:
          break;
      }

      return date;
    }
  }
};
</script>
